import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				A propos de nous - A propos de Grand Puy Ski Retreat
			</title>
			<meta name={"description"} content={"Grand Puy Ski Retreat est l'endroit où les pistes de ski exaltantes rencontrent l'étreinte tranquille des paysages de neige immaculés."} />
			<meta property={"og:title"} content={"A propos de nous - A propos de Grand Puy Ski Retreat"} />
			<meta property={"og:description"} content={"Grand Puy Ski Retreat est l'endroit où les pistes de ski exaltantes rencontrent l'étreinte tranquille des paysages de neige immaculés."} />
			<meta property={"og:image"} content={"https://normanfun.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://normanfun.live/img/6347345.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://normanfun.live/img/6347345.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://normanfun.live/img/6347345.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://normanfun.live/img/6347345.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://normanfun.live/img/6347345.png"} />
			<meta name={"msapplication-TileImage"} content={"https://normanfun.live/img/6347345.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Statistics-7">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://normanfun.live/img/3.jpg"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="40px 0px 0px 50px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 10px 0px" color="--primary" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
						A propos de nous
					</Text>
					<Text margin="0px 0px 30px 0px" color="--dark" font="normal 700 42px/1.2 --fontFamily-sans">
						Notre essence
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#75767d">
						Grand Puy Ski Retreat est l'endroit où les pistes de ski exaltantes rencontrent l'étreinte tranquille des paysages de neige immaculés. Fondé sur les principes de l'aventure, de la famille et de la joie pure des sports d'hiver, Grand Puy est devenu une escapade appréciée de ceux qui recherchent à la fois l'adrénaline et la tranquillité.
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						margin="30px 0px 0px 0px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						grid-template-rows="repeat(2, 1fr)"
						lg-margin="50px 0px 0px 0px"
						md-margin="40px 0px 0px 0px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box min-width="100px" min-height="80px" padding="0px 5px 0 5px" margin="0px 0px 30px 0px">
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Notre parcours
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Né d'une vision de créer un lieu de retraite qui s'harmonise avec les contours naturels de son havre enneigé, Grand Puy Ski Retreat a été façonné par la terre elle-même. Des pistes délicatement tracées, des remontées mécaniques à couper le souffle et des lodges confortables ont été placés de manière réfléchie afin de préserver la beauté intrinsèque du paysage.
							</Text>
						</Box>
						<Box min-width="100px" padding="0px 5px 0 5px" margin="0px 0px 30px 0px" min-height="80px">
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Notre philosophie
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Au Grand Puy, nous pensons que l'expérience de la montagne ne se limite pas à la pratique du ski ou du snowboard. Il s'agit de créer des souvenirs inoubliables, de profiter de la majesté de la montagne et de favoriser une communauté où chaque client se sent comme chez lui, qu'il soit un novice ou un professionnel chevronné.
							</Text>
						</Box>
						<Box min-width="100px" padding="0px 5px 0 5px" margin="0px 0px 30px 0px" min-height="80px">
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								L'expérience du Grand Puy
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Un terrain varié : Des pentes douces pour débutants aux pistes noires difficiles, il y en a pour tous les niveaux de skieurs et de snowboarders. Qualité et attention : Nous sommes fiers de maintenir la plus haute qualité de service, d'équipement et d'installations.
							</Text>
						</Box>
						<Box min-width="100px" min-height="80px" padding="0px 5px 0 5px" margin="0px 0px 35px 0px">
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Un cœur pour l'aventure
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Nos guides et instructeurs ne sont pas seulement des experts dans leur domaine - ce sont des ambassadeurs de l'esprit montagnard, désireux de partager leur passion. Dans chaque décision, nous prenons en compte notre impact sur l'environnement, en nous efforçant de faire en sorte que notre plaisir de la montagne aujourd'hui puisse être partagé avec les générations de demain.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 0px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
			>
				<Image max-width="340px" src="https://normanfun.live/img/4.jpg" md-margin="0px 0px 20px 0px" />
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--dark" font="--headline2" md-margin="0px 0px 30px 0px">
					Notre vision
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
					Tournée vers l'avenir, Grand Puy Ski Retreat s'engage à innover pour offrir les meilleures expériences en montagne tout en préservant l'intégrité et la beauté de notre environnement alpin. Nous ne sommes pas seulement une destination - nous sommes une tradition, une évasion et une communauté.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});